/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    common: {
      shuffle: function (a) {
        var j, x, i;
        for (i = a.length - 1; i > 0; i--) {
          j = Math.floor(Math.random() * (i + 1));
          x = a[i];
          a[i] = a[j];
          a[j] = x;
        }
      },
      resumeLine: function (timeLine, time) {
        TweenLite.delayedCall(time, function () {
          timeLine.play();
        });
      },
      animateAs: function () {
        var As = $("footer.footer .logo svg path");
        if (!As) {
          return;
        }

        var num = As.length;

        if ($("body").hasClass("home")) {
          // make an array of keys
          var keys = [];
          for (i = 0; i < num; i++) {
            keys.push(i);
          }
          Sage.common.shuffle(keys);

          var timeLine = new TimelineMax();
          for (i = 0; i < num; i++) {
            var speed = 0.006 * (i + i);
            timeLine.set(As, { visibility: "hidden" });
            timeLine.to(As.eq(keys[i]), speed, { visibility: "visible" });
          }
          timeLine.play();
        } else {
          As.eq(Math.floor(Math.random() * num + 1)).css(
            "visibility",
            "visible"
          );
        }
      },
      cover_footer: function () {
        $(".cover_footer").each(function () {
          var parent = $(this).parents(".cover_footer_parent");
          var _x = parent.offset().left;
          var _w = parent.outerWidth();
          var _h = $("footer.footer").height();
          $(this).css({
            left: _x,
            width: _w,
            height: _h,
          });
        });
      },
      init: function () {
        // JavaScript to be fired on all pages
        $(document).foundation(); // Foundation JavaScript

        Sage.common.cover_footer();
        $(window).load(Sage.common.cover_footer);
        $(window).resize(Sage.common.cover_footer);

        Sage.common.animateAs();

        $(".hamburger").click(function () {
          $("body").toggleClass("open");
          return false;
        });

        new WOW().init();
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    home: {
      loaded: [],
      animate: function () {
        if (Sage.home.loaded.length === 2) {
          $("body.home").addClass("animate-in");
        }
      },
      init: function () {
        if (window.backgrounds.length) {
          var backgrounds = window.backgrounds;
          var bg = backgrounds[Math.floor(Math.random() * backgrounds.length)];

          $(".home_overlay").css(
            "backgroundImage",
            "url(" + bg.home_overlay + ")"
          );
          $(".red_overlay").css(
            "backgroundImage",
            "url(" + bg.red_overlay + ")"
          );

          $(".home_overlay").imagesLoaded({ background: true }, function () {
            Sage.home.loaded.push("home_overlay");
            Sage.home.animate();
          });
          $(".red_overlay").imagesLoaded({ background: true }, function () {
            Sage.home.loaded.push("home_overlay");
            Sage.home.animate();
          });
        }

        $(".latest").click(function () {
          var link = $("a", this).attr("href");
          window.location = link;
        });
      },
    },
    projects_collection: {
      tax_hover: function () {
        $(".no-touch .project_types a").hover(
          function () {
            var project_type = $(this).data("project_type");
            $(".projects li:not(.project_type_" + project_type + ")").addClass(
              "irrelevant"
            );
          },
          function () {
            $(".irrelevant").removeClass("irrelevant");
          }
        );
      },
      project_hover: function () {
        $(".no-touch .project a").hover(
          function () {
            $(this)
              .next(".full_height-fixedLeft")
              .stop()
              .delay(250)
              .fadeIn(250);
          },
          function () {
            $(this).next(".full_height-fixedLeft").stop().fadeOut(250);
          }
        );
      },
    },
    post_type_archive_projects: {
      init: function () {
        Sage.projects_collection.tax_hover();
        Sage.projects_collection.project_hover();

        var _w = $(window).width();
        var SHOWNONE = "shownoneofthese";

        // lets make these things animate
        var iso_args = {
          layoutMode: "vertical",
          hiddenStyle: {
            opacity: 0,
          },
          visibleStyle: {
            opacity: 1,
          },
        };

        var $project_grid = $("ul.projects").isotope(iso_args);
        var $tax_grid = $("ul.project_types").isotope(iso_args);

        $(window).load(function () {
          $project_grid.isotope("layout");
          $tax_grid.isotope("layout");
        });

        $(".project_types a").click(function () {
          if ($("ul.project_types").hasClass("isFiltered")) {
            if (_w < 640) {
              $project_grid.isotope({ filter: SHOWNONE });
            } else {
              $project_grid.isotope({ filter: "*" });
            }
            $tax_grid.isotope({ filter: "*" });
            $(".all").css("display", "none");
            $("ul.project_types").removeClass("isFiltered");
          } else {
            var filterValue = $(this).data("project_type");
            $project_grid.isotope({ filter: ".project_type_" + filterValue });
            $tax_grid.isotope({ filter: "." + filterValue });
            $("ul.project_types").addClass("isFiltered");
            $(".all").css("display", "inline-block");
          }
          return false;
        });

        $(".all").click(function () {
          $project_grid.isotope({ filter: "*" });
          $tax_grid.isotope({ filter: "*" });
          $(".all").css("display", "none");
          return false;
        });

        $(".view-all a").click(function () {
          $(this).parent().addClass("active").siblings().removeClass("active");
          $(".project_types").hide();
          $project_grid.isotope({ filter: "*" });
          $("ul.project_types").removeClass("isFiltered");

          return false;
        });
        $(".view-categories a").click(function () {
          $(this).parent().addClass("active").siblings().removeClass("active");
          $(".project_types").show();
          $tax_grid.isotope({ filter: "*" });
          $project_grid.isotope({ filter: SHOWNONE });
          $("ul.project_types").removeClass("isFiltered");

          return false;
        });
      },
    },
    tax_project_type: {
      init: function () {
        Sage.projects_collection.project_hover();
      },
    },
    single_projects: {
      init: function () {
        $(".projectphotos").click(function (e) {
          e.preventDefault();
          $("html,body").animate(
            {
              scrollTop: $(".project_gallery").position().top,
            },
            500
          );
        });
      },
    },
    blog: {
      init: function () {
        $(".no-touch .post a").hover(
          function () {
            $(this)
              .next(".full_height-fixedRight")
              .stop()
              .delay(250)
              .fadeIn(250);
          },
          function () {
            $(this).next(".full_height-fixedRight").stop().fadeOut(250);
          }
        );
        $(window).resize(function () {
          $(".no-touch .post .full_height-fixedRight").hide();
        });
      },
    },
    post_type_archive_idea: {
      init: function () {
        $(".popup-video").magnificPopup({
          type: "iframe",
          removalDelay: 160,
          preloader: false,
          fixedContentPos: false,
        });
      },
    },
    post_type_archive_collaboration: {
      page: 1,
      loadmore: function (site_url) {
        var page = Sage.post_type_archive_collaboration.page + 1;
        var url =
          site_url +
          "/wp-json/wp/v2/collaboration?per_page=36&page=" +
          page +
          "&filter[orderby]=menu_order";
        $.ajax({
          url: url,
          success: function (data) {
            if (data.length < 36) {
              $(".loadmore a").remove();
            }
            $.each(data, function (i, insp) {
              if (insp && insp.better_featured_image) {
                var img =
                  insp.better_featured_image.media_details.sizes.thumbnail
                    .source_url;
                if (
                  insp.better_featured_image.source_url.indexOf(".gif") !== -1
                ) {
                  img = insp.better_featured_image.source_url;
                }
                $("#collaboration").append(
                  '<div class="wow column"><a href="' +
                    insp.better_featured_image.source_url +
                    '" class="collaboration-photo" title="' +
                    insp.title.rendered +
                    '"><div class="the_collaboration" style="background-image: url(' +
                    img +
                    ');"></div></a></div>'
                );
              }
            });
            Sage.post_type_archive_collaboration.page++;
          },
          complete: function () {
            $(".loadmore a").removeClass("disabled");
          },
        });
      },
      init: function () {
        // add light box
        $("#collaboration").magnificPopup({
          delegate: "a",
          type: "image",
          tLoading: "Loading image #%curr%...",
          fixedContentPos: true,
          gallery: {
            enabled: true,
            navigateByImgClick: false,
            preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
          },
          image: {
            tError:
              '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function (item) {
              return item.el.attr("title");
            },
          },
        });

        $(window).resize(function () {
          $(".wow.column").removeClass("animated").addClass("animated");
        });

        // add load more function
        $(".loadmore a").click(function () {
          Sage.post_type_archive_collaboration.loadmore(
            $(this).data("site_url")
          );
          $(this).addClass("disabled");
          return false;
        });
      },
    },
    team: {
      init: function () {
        // populate bio
        $(".headshot").on("click", function () {
          if ($(window).width() < 640) {
            var person = $(this).parents(".team_member");
            var img = $(this).clone();
            var copy = $(".bio_padding", person).clone();

            $("#team_member_bio .mobile_bio").html(img);
            $("#team_member_bio .mobile_bio").append(copy);

            $("#team_member_bio").foundation("open");
            // close on resize
            $(window).resize(function () {
              $("#team_member_bio").foundation("close");
            });
          }
        });
      },
    },
    contact: {
      init: function () {
        $(".gform_wrapper textarea").autogrow({
          vertical: true,
          horizontal: false,
        });
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
